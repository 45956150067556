<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <span class="titulo-header">Crear Solicitud</span>
      </v-col>
      <v-col cols="12" md="2" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="toDashboard"
          style="margin-right: 11px;"
        >
          Cancelar
        </v-btn>
      </v-col>
    </v-row>
    <div class="pt-5">
      <commond-form
        :request="request"
        :route="'/v1/solicitud/insert'"
        :run="run"
        :action="'POST'"
        @cancel="run = false"
        @success="changeComponent"
        @afterError="error"
      >
        <template v-slot:data>
          <CreateSolicitudDataForm
            :currentStep="currentStep"
            @ChangeOnlyStep="ChangeOnlyStep"
          />
          <CreateSolicitudClienteForm
            :currentStep="currentStep"
            @ChangeOnlyStep="ChangeOnlyStep"
          />
          <CreateSolicitudGeneralForm
            :currentStep="currentStep"
            :ramo="firstStepData.categoriaSelected"
            :tipoSolicitud="firstStepData.tipoSelected"
            :isAgente="isAgente"
            @ChangeOnlyStep="ChangeOnlyStep"
            @goToSave="goToSave"
          />
          <SeguimientoSolicitudForm
            v-if="!isAgente"
            :currentStep="currentStep"
            :ramo="firstStepData.categoriaSelected"
            @ChangeOnlyStep="ChangeOnlyStep"
            @goToSave="goToSave"
          />
        </template>
      </commond-form>
      <CommonSolicitudCreadaModal
        :dialog="successSolicitud"
        @userChoice="changeView"
      />
    </div>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import CreateSolicitudDataForm from "./CreateSolicitudData/CreateSolicitudDataForm.vue";
import CreateSolicitudClienteForm from "./CreateSolicitudData/CreateSolicitudClienteForm.vue";
import CreateSolicitudGeneralForm from "./CreateSolicitudData/CreateSolicitudGeneralForm.vue";
import SeguimientoSolicitudForm from "./CreateSolicitudData/SeguimientoSolicitudForm.vue";
import CommonSolicitudCreadaModal from "@/components/commonComponents/CommonSolicitudCreadaModal.vue";
import { getProductoList } from "../../../../../services/productos/product.service";

export default {
  components: {
    CreateSolicitudDataForm,
    CommondForm,
    CreateSolicitudClienteForm,
    CreateSolicitudGeneralForm,
    SeguimientoSolicitudForm,
    CommonSolicitudCreadaModal,
  },
  data() {
    return {
      request: {},
      firstStepData: {
        ramo: null,
        tipoSelected: null,
      },
      secondStepData: {},
      thirdStepData: {},
      currentStep: 1,
      run: false,
      successSolicitud: false,
      solicitudCreada: {},
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  methods: {
    ChangeOnlyStep(toStep, polizaData) {
      if (this.currentStep == 1) {
        window.dataLayer.push({
          event: "ITFY_solicitud_cliente_v",
          no_cotizacion: undefined,
          userID: localStorage.getItem("agenteMail"),
          correo: undefined,
          telefono: undefined,
        });
      }
      console.log({ toStep, polizaData });
      this.currentStep = toStep;
      if (polizaData != null)
        switch (toStep) {
          case 2:
            this.firstStepData = polizaData;
            break;
          case 3:
            this.secondStepData = polizaData;
            break;
          case 4:
            this.thirdStepData = polizaData;
            break;
        }
    },
    toDashboard() {
      this.$router.push("main-hub/2");
    },
    goToSave(data) {
      if (!this.isAgente)
        this.request = {
          ...this.firstStepData,
          ...this.secondStepData,
          ...this.thirdStepData,
          ...data,
        };
      else
        this.request = {
          ...this.firstStepData,
          ...this.secondStepData,
          ...data,
          ...{
            operadorUuid: null,
            procesoCotizacionSelected: "Cotización",
            solicitudCompletaSelected: false,
            checkPendienteInfo: false,
            checkCancelacion: false,
            causaPendienteInformacion: null,
          },
        };
      this.request.agenteId = this.firstStepData.agente;
      this.request.productoId = this.firstStepData.producto_id;
      this.run = true;
    },
    changeComponent(response) {
      this.solicitudCreada = response.data;
      if (this.isAgente) this.successSolicitud = true;
      else {
        if (this.checkIfNeedBeneficiary()) {
          this.$router.push({
            name: "detalles-solicitud",
            params: {
              id: this.solicitudCreada.id,
            },
            query: {
              beneficiarios: "true",
            },
          });
        } else
          this.$router.push("/detalles-solicitud/" + this.solicitudCreada.id);
      }
    },
    changeView(val) {
      if (val) {
        if (this.checkIfNeedBeneficiary()) {
          this.$router.push({
            name: "detalles-solicitud",
            params: {
              id: this.solicitudCreada.id,
            },
            query: {
              beneficiarios: "true",
            },
          });
        } else
          this.$router.push("/detalles-solicitud/" + this.solicitudCreada.id);
      } else location.reload();
    },
    async checkIfNeedBeneficiary() {
      const products = await getProductoList();
      const currentProduct = products.find((e) => e.id == this.solicitudCreada.producto_id);
      return currentProduct ? currentProduct.beneficiario : false;
    },
  },
  computed: {
    isAgente() {
      return this.rol == "AGENT";
    },
  },
};
</script>
